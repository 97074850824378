<template>
  <div>
    <sub-head v-bind:subTitle="content['파트너십']"></sub-head>
    <div class="page_padding">
      <div class="paddingTB">
        <div class="text-center">
          <span class="fontM fontBold text333"
            >다양한 산학
            <span class="textPink fontBold">협력 관계 구축</span></span
          >
        </div>
        <div class="text-center paddingTB1">
          <span class="fontS">
            빠른 시장 진입과 사업화 성공을 위하여 협회, 기관, 기업과의 협력체계
            구축을 우선적으로 진행하고 있습니다.
          </span>
        </div>
        <div class="verticalSpacer-half"></div>
        <div>
          <content-block
            v-bind:contents="businessContent['cooperation']"
            extend="jpg"
          ></content-block>
        </div>
      </div>
    </div>
    <div class="page_padding bgBackground">
      <div class="paddingTB">
        <div class="fontExtraBold fontM text333">파트너스</div>
        <div class="verticalSpacer-half"></div>
        <div class="flex-container">
          <div v-for="logo in 16" :key="logo" class="imgWrap">
            <img :src="getImgSrc(logo)" />
          </div>
        </div>
      </div>
    </div>
    <div class="page_padding bgPartnership textWhite">
      <div class="d-flex paddingTB">
        <div class="partner_header">
          <div class="partner_title">
            <span class="fontM fontBold">파트너십 문의하기</span>
          </div>
          <div>
            <span class="word_break fontLight fontS"
              >문의 사항은 양식을 통하여 전달해 주시면 최대한 빠르게 검토하여
              답변 드리겠습니다.</span
            >
          </div>
        </div>
        <div class="input-form">
          <form id="mail-form" method="post" action="./email.php">
            <ul>
              <li>
                <span class="title">회사명</span>
                <input
                  v-model="inp_company"
                  placeholder="회사명을 입력해주세요."
                  type="text"
                  id="I_Company"
                  name="I_Company"
                />
              </li>
              <li>
                <span class="title">이름</span>
                <input
                  v-model="inp_name"
                  placeholder="이름을 입력해주세요."
                  type="text"
                  id="I_Name"
                  name="I_Name"
                />
              </li>
              <li>
                <span class="title">이메일</span>
                <input
                  v-model="inp_email"
                  type="inp_email"
                  id="I_Email"
                  name="I_Email"
                />
              </li>
              <li>
                <span class="title">연락처</span>
                <input
                  v-model="inp_tel"
                  placeholder="-를 포함하여 입력해주세요."
                  type="text"
                  id="I_Tel"
                  name="I_Tel"
                />
              </li>
              <li>
                <span class="title">주소</span>
                <input
                  v-model="inp_addr"
                  placeholder="주소를 입력해주세요."
                  type="text"
                  id="I_Addr"
                  name="I_Addr"
                />
              </li>
              <li>
                <div style="position:relative;">
                  <span class="title">내용</span>
                  <div class="text-right bgBack">
                    <textarea
                      v-model="inp_contents"
                      maxlength="1000"
                      rows="20"
                      id="I_Contents"
                      name="I_Contents"
                      required
                    ></textarea>
                  </div>
                </div>
              </li>
              <li>
                <div class="input-button">
                  <input
                    type="submit"
                    class="input-button"
                    value="보내기"
                    id="I_Submit"
                    name="I_Submit"
                  />
                </div>
              </li>
            </ul>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import jsonContents from "../../assets/source/json/sub_top.json";
import subHead from "../../components/sub_head.vue";
import ContentBlock from "../../components/content_block.vue";
import businessContents from "../../assets/source/json/business_content.json";

export default {
  components: {subHead, ContentBlock},
  data() {
    return {
      content: jsonContents,
      businessContent: businessContents[2],
      inp_company: "",
      inp_name: "",
      inp_email: "",
      inp_tel: "",
      inp_addr: "",
      inp_contents: "",
    };
  },
  methods: {
    getImgSrc(src) {
      if (src < 10) {
        src = "partner_logo0" + src;
      } else {
        src = "partner_logo" + src;
      }

      var images = require.context(
        "../../assets/source/images/",
        false,
        /\.png$/
      );
      return images("./" + src + ".png");
    },
  },
};
</script>

<style scoped>
.flex-container {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;
}
.flex-container .imgWrap {
  margin: 0.5rem 0;
  background-color: white;
  width: 23%;
  border: 1px solid #e5e5e5;
}
.imgWrap img {
  width: 100%;
}
ul li {
  list-style: none;
}
span.title {
  width: 20%;
  float: left;
}
li input,
li textarea {
  float: right;
  width: 70%;
  height: 25px;
  padding: 5px 10px;
  outline: none;
  border: none;
}
li {
  width: 100%;
  height: 40px;
  margin: 0.5rem 0;
  clear: both;
}
ul {
  width: 100%;
}
div.input-form {
  width: 100%;
}
textarea {
  resize: none;
  height: 150px !important;
  padding: 10px !important;
}
.bgPartnership {
  background-image: url("../../assets/source/images/partner_bg.jpg");
}
.input-button input {
  width: 6rem;
  float: unset;
  height: 2.5rem;
  border-radius: 5px;
  background-color: black;
  color: white;
  outline: none;
  cursor: pointer;
  margin-top: 1rem;
}
.input-button {
  width: 70%;
  float: right;
}
.partner_header {
  text-align: left;
  width: 40%;
}

@media (max-width: 767px) {
  .bgPartnership > .d-flex {
    flex-direction: column;
  }
  .partner_header {
    width: 100%;
  }
  span.title {
    text-align: left;
  }
  li input,
  li textarea {
    width: 70%;
  }
  .input-form {
    padding: 1rem 0;
  }
  .partner_header .partner_title {
    padding: 0.5rem 0;
  }
  .input-button {
    width: 80%;
  }
}
</style>
